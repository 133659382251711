import inlineSVG from 'Vendor/inlineSvg';

import whenVisible from 'Utils/whenVisible.js';
import analytics from 'Scripts/analytics.js';
import calendly from 'Scripts/calendly.js';
import trackingCookieHelper from 'Scripts/trackingInformationCookieHelper.js';

document.addEventListener('DOMContentLoaded', () => {
	/* Load SVGs inline, so we can colour them */
	inlineSVG.init({
		svgSelector: 'img.svg',
		initClass: 'js-inlinesvg',
	}, () => {
		document.documentElement.classList.add('is-loaded');
	});

	analytics.trackTaggedAnchors();
	calendly.setUpCalendly();
	trackingCookieHelper.storeTrackingInformationInCookie();

	// Apply class when element is visible
	whenVisible();
});
